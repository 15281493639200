import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { ExternalCheckout } from '../../../modules/external-checkout'
import { InventorySourcesModule } from '../../../modules/vsf-inventory-sources'
import { ProductAlertsModule } from '../../../modules/vsf-product-alerts'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from '@vue-storefront/core/modules/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { GoogleTagManagerModule } from 'src/modules/google-tag-manager';
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { Wordpress } from 'src/modules/wordpress'
import { BannersliderModule } from 'src/modules/vsf-bannerslider'
import { SentryModule } from 'src/modules/netzkollektiv-sentry'
import { ConfigModule } from 'src/modules/vsf-config'

import { registerModule } from '@vue-storefront/core/lib/modules'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(ExternalCheckout)
  registerModule(CartModule)
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(BreadcrumbsModule)
  registerModule(GoogleTagManagerModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(InventorySourcesModule)
  registerModule(ProductAlertsModule)
  registerModule(Wordpress)
  registerModule(BannersliderModule)
  registerModule(SentryModule)
  registerModule(ConfigModule)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
]

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations/esm/captureconsole'
import { RewriteFrames } from '@sentry/integrations/esm/rewriteframes'
import Vue from 'vue'

const seenMessages = {};
const beforeSend = function (event) {
    // prevent dual send
    if (event.message) {
      if (seenMessages[event.message]) {
      return null;
      }
      seenMessages[event.message] = true;
    }
    if (Object.values(seenMessages).length > 100) {
      return null;
    }
    if (event.message && event.message.indexOf('No participant for id:') !== -1) {
      return null;
    }
    if (event.message && event.message.match(/^\d{4}-\d{2}-.*?Z\s/)) {
      return {
        event,
        message: event.message.slice(24)
      };
    } 
    return event;
}

export function initSentry(dsn, router) {
  const sentryEnv = typeof location !== 'undefined' ? location.origin : 'SSR-' + process.env.NODE_ENV

  Sentry.init({
    Vue,
    dsn: dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }), 
      new RewriteFrames(), 
      new CaptureConsole({
        levels: ['error']
      })
    ],
    tracesSampleRate: 1.0,
    environment: sentryEnv,
    beforeSend: beforeSend
  });
}

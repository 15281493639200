<template>
  <div class="product__base-price" v-if="canShow">
    <span class="product__base-price__regular" v-if="(!special && special !== 0) && min">
      <span class="min-from">ab</span>
      <span class="unit">{{ currency(baseMin) }}/{{ basePriceUnit }}</span> 
      <span class="price">{{ cleanPrice(baseMin) }}</span>
    </span>
    <span class="product__base-price__regular" v-if="(!special && special !== 0) && !min">
      <span class="unit">{{ currency(baseRegular) }}/{{ basePriceUnit }}</span> 
      <span class="price">{{ cleanPrice(baseRegular) }}</span>
    </span>
    <span class="product__base-price__special" v-if="special || special === 0">
      <span class="unit">{{ currency(baseSpecial) }}/{{ basePriceUnit }}</span> 
      <span class="price">{{ cleanPrice(baseSpecial) }}</span>
    </span>
    <span class="product__base-price__old" v-if="special || special === 0">
      <span class="unit">{{ currency(baseRegular) }}/{{ basePriceUnit }}</span> 
      <span class="price">{{ cleanPrice(baseRegular) }}</span>
    </span>
  </div>
</template>

<script>
import { price } from '@vue-storefront/core/filters/price'
import { basePriceUnit } from 'theme/helpers/custom';

export default {
  props: {
    min: {
      type: [String, Number],
      default: null
    },
    regular: {
      type: [String, Number],
      default: null,
      required: true
    },
    special: {
      type: [String, Number],
      default: null
    },
    factor: {
      type: [String, Number],
      default: null,
      required: true
    },
    unit: {
      type: [String, Number],
      default: null,
      required: true
    }
  },
  computed: {
    basePriceUnit () {
      if (!isNaN(parseFloat(this.unit)) && isFinite(this.unit)) {
        return basePriceUnit(this.unit)
      }
      return this.unit
    },
    baseMin () {
      return this.min / this.factor
    },
    baseRegular () {
      return this.regular / this.factor
    },
    baseSpecial () {
      return this.special / this.factor
    },
    canShow () {
      return this.unit && this.factor
    }
  },
  methods: {
    currency (rawPrice) {
      return price(rawPrice).split(' ')[1]
    },
    cleanPrice (rawPrice) {
      return price(rawPrice).split(' ')[0]
    }
  }
}
</script>

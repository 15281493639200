import {
  getBundleOptionsValues,
  getSelectedBundleOptions
} from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers';
import rootStore from '@vue-storefront/core/store'

export function scrollTo (anchor) {
  const element = document.querySelector(anchor)
  const topPos = element.getBoundingClientRect().top + window.pageYOffset

  window.scrollTo({
    top: topPos, // scroll so that the element is at the top of the view
    behavior: 'smooth' // smooth scroll
  })
}

export function getSelectedBundleOptionValue (product, option) {
  let selectedOptions = getSelectedBundleOptions(product)
  let optionValues = getBundleOptionsValues(selectedOptions, product.bundle_options)
  const selectedOption = selectedOptions.find(o => option.option_id === o.option_id)
  if (selectedOption) {
    return optionValues.find(val => val.id === selectedOption.option_selections.find(() => true))
  }
  return {}
}

export function basePriceUnit (unit) {
  return optionLabel(
    rootStore.state.attribute,
    { attributeKey: 'baseprice_product_unit', optionId: unit }
  )
}
